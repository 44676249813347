exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-templates-blog-post-tsx-content-file-path-opt-build-repo-content-blog-angular-service-blir-ikke-singleton-index-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/opt/build/repo/content/blog/angular-service-blir-ikke-singleton/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-opt-build-repo-content-blog-angular-service-blir-ikke-singleton-index-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-opt-build-repo-content-blog-backup-av-dockerisert-postgresql-db-index-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/opt/build/repo/content/blog/backup-av-dockerisert-postgresql-db/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-opt-build-repo-content-blog-backup-av-dockerisert-postgresql-db-index-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-opt-build-repo-content-blog-hva-er-ng-template-hva-er-webpack-en-introduksjon-index-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/opt/build/repo/content/blog/hva-er-ng-template/hva-er-webpack-en-introduksjon/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-opt-build-repo-content-blog-hva-er-ng-template-hva-er-webpack-en-introduksjon-index-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-opt-build-repo-content-blog-hva-er-webpack-en-introduksjon-index-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/opt/build/repo/content/blog/hva-er-webpack-en-introduksjon/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-opt-build-repo-content-blog-hva-er-webpack-en-introduksjon-index-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-opt-build-repo-content-blog-objekter-i-javascript-index-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/opt/build/repo/content/blog/objekter-i-javascript/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-opt-build-repo-content-blog-objekter-i-javascript-index-mdx" */)
}

